import React from "react";
import { css } from "@emotion/react";
import tw from "twin.macro";
import linkResolver from "../utils/linkResolver";
import { Link, graphql } from "gatsby";
export default function CTA({ data }) {
  return (
    <div className="relative py-16 bg-white dark:bg-black">
      <div
        className="absolute inset-x-0 top-0 hidden bg-white dark:bg-black h-1/2 lg:block"
        aria-hidden="true"
      />
      <div className="pt-8 mx-auto bg-yellow-50 dark:bg-blue-dark max-w-7xl lg:dark:bg-transparent lg:bg-transparent lg:pt-0 lg:px-8">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
            <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
              <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                <img
                  className="object-cover object-center shadow-2xl rounded-3xl"
                  src={data?.primary?.image?.fluid?.src}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="relative bg-yellow-50 dark:bg-blue-dark lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
            <div
              className="absolute inset-0 hidden overflow-hidden rounded-3xl lg:block"
              aria-hidden="true"
            >
              <svg
                className="absolute transform bottom-full left-full translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-blue-light/20 dark:text-white/20"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                />
              </svg>
              <svg
                className="absolute transform top-full -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-blue-light/20 dark:text-white/20"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                />
              </svg>
            </div>
            <div className="relative max-w-md px-4 py-12 mr-auto space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
              <div
                className="prose dark:prose-dark max-w-none"
                css={css`
                  h1,
                  h2,
                  h3 {
                    ${tw`mb-4 text-3xl lg:text-4xl`}
                  }
                  p {
                    ${tw`text-2xl lg:text-3xl `}
                  }
                `}
                dangerouslySetInnerHTML={{ __html: data?.primary?.text?.html }}
              ></div>
              {data?.primary?.button_link?.link_type === "Document" ? (
                <Link
                  className="block w-full px-5 py-3 text-base font-bold text-center text-black uppercase border border-transparent rounded-md shadow-md bg-orange dark:hover:bg-blue-light hover:bg-blue-dark hover:text-white dark:hover:text-blue-dark sm:inline-block sm:w-auto"
                  to={linkResolver(data?.primary?.button_link)}
                >
                  {data?.primary?.button_label}
                </Link>
              ) : (
                <a
                  className="block w-full px-5 py-3 text-base font-bold text-center text-black uppercase border border-transparent rounded-md shadow-md bg-orange dark:hover:bg-blue-light hover:bg-blue-dark hover:text-white dark:hover:text-blue-dark sm:inline-block sm:w-auto"
                  href={data?.primary?.button_link?.url}
                  target={data?.primary?.button_link?.target}
                >
                  {data?.primary?.button_label}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  fragment CallToAction on PrismicPageBodyCallToAction {
    id
    slice_type
    primary {
      button_label
      text {
        html
      }
      button_link {
        uid
        url
        type
        link_type
        target
      }
      image {
        fluid {
          src
        }
      }
    }
  }
  fragment HomepageCallToAction on PrismicHomepageBodyCallToAction {
    id
    slice_type
    primary {
      button_label
      text {
        html
      }
      button_link {
        uid
        url
        type
        link_type
        target
      }
      image {
        fluid {
          src
        }
      }
    }
  }
`;
